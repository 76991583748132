import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Privacy from "./components/Privacy/Privacy";
import TermsCondition from "./components/TermsCondition/TermsCondition";
import SessionDetails from "./components/PsychologistDashboard/SessionDetails/SessionDetails";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import ScrollToTop from "./components/ScrollToTop";
import UserProfile from "./components/UserProfile/UserProfile";
import PsychologistProfile from "./components/PsychologistProfile/PsychologistProfile";
import VideoSessionHeroSection from "./components/VideoSession/VideoSessionHeroSection";
import Payment from "./components/Payment/Payment";
import PaySecondPage from "./components/Payment/PaySecondPage/PaySecondPage";
import PsychologistDashboard from "./components/PsychologistDashboard/PsychologistDashboard";
import RatingVideoSession from "./components/VideoSession/RatingVideoSession/RatingVideoSession";
import PaySuccess from "./components/Payment/PaySuccess/PaySuccess";
import PsychologistSignUp from "./components/PsychologistSignUp/PsychologistSignUp";
import PsychologistSignIn from "./components/PsychologistSignIn/PsychologistSignIn";
import PsychologistDetailsPage from "./components/PsychologistDetails/PsychologistDetailsPage";
import AllPsychologists from "./components/PsychologistDashboard/AllPsychologists/AllPsychologists";
import DashboardNavigation from "./components/DashboardNavigation/DashboardNavigation";
import Journal from "./components/UserProfile/Journal/Journal";
import SelectDoctor from "./components/SelectDoctor/SelectDoctor";
import SignUpSecondPage from "./components/SignUpSecondPage/SignUpSecondPage";
import AvailableDate from "./components/AvailableDate/AvailableDate";
import Blogs from "./components/Blogs/Blogs";
import BlogLearnMore from "./components/Blogs/BlogLearnMore/BlogLearnMore";
import PrivateRoute from "./components/Route/PrivateRoute/PrivateRoute";
import ChatPrivateRoute from "./components/Route/ChatPrivateRoute/ChatPrivateRoute";
import AdminDashboardNavigation from "./components/AdminDashboard/AdminDashboardNavigation";
import AllPsychologistTable from "./components/AdminDashboard/AllPsychologistTable";
import AddBlog from "./components/Blogs/AddBlog/AddBlog";

import PsychologistDashboardNavigation from "./components/PsychologistDashboardNavigation/PsychologistDashboardNavigation";
import BookSession from "./components/BookSession/BookSession";
import SessionHistory from "./components/UserProfile/SessionHistory/SessionHistory";
import Recommend from "./components/UserProfile/Recommend/Recommend";
import CalenderView from "./components/UserProfile/CalenderView/CalenderView";
import useAuth from "./hooks/useAuth";
import AllBlogs from "./components/Blogs/AllBlogs/AllBlogs";
import PageNoteFound from "./components/PageNotFound.js/PageNoteFound";
import HomeworkContainer from "./components/HomeworkBuilder/HomeworkContainer/HomeworkContainer";
import SelectionOfDoctors from "./components/SelectionOfDoctors/SelectionOfDoctors";
import WaitForApproval from "./components/PsychologistDashboard/WaitForApproval/WaitForApproval";
import EditPsychologistInfo from "./components/PsychologistDashboard/EditPsychologistInfo/EditPsychologistInfo";
import PsychologistDetails from "./components/PsychologistDetails/Component/PsychologistDetails";
import AdminPsychologistDetails from "./components/AdminDashboard/AdminPsychologistDetails";
import EditPatientInfo from "./components/UserProfile/EditPatientInfo/EditPatientInfo";
import PsychologistRoute from "./components/Route/PsychologistRoute/PsychologistRoute";
import AdminRoute from "./components/Route/AdminRoute/AdminRoute";
import VideoPage from "./components/VideoPage/VideoPage";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import PsychologistPayment from "./components/PsychologistDashboard/PsychologistPayment/PsychologistPayment";
import PsychologistPaymentDetails from "./components/AdminDashboard/PsychologistPaymentDetails";
import AdminSignin from "./components/AdminDashboard/AdminSignin";
import PsychologistPaymentRequest from "./components/AdminDashboard/PsychologistPaymentRequest";
import FAQmain from "./components/FAQmain/FAQmain";
import "aos/dist/aos.css";
import ServiceReadMore from "./components/Home/Services/ServiceReadMore";
import Counseling from "./components/Home/Services/Counseling";
import Teams from "./components/Teams/Teams";
import UsersTable from "./components/AdminDashboard/UsersTable";
import BlogerSignup from "./components/BlogerSignup/BlogerSignup";
import BlogerSignin from "./components/BlogerSignup/BlogerSignin";
import BlogerRoute from "./components/Route/BlogerRoute/BlogerRoute";
import BlogerDashboardNavigation from "./components/BlogerDashboard/BlogerDashboardNavigation";
import BlogerDashboard from "./components/BlogerDashboard/BlogerDashboard";
import AddBlogerInfo from "./components/BlogerDashboard/AddBlogerInfo";
import UsersJourney from "./components/SignUp/UsersJourney";
import PromoCode from "./components/AdminDashboard/PromoCode";
import ChatList from "./components/ChatList/ChatList";
import AddChatUser from "./components/AdminDashboard/AddChatUser";
import ChatLogin from "./components/ChatLogin/ChatLogin";
import SignUpChat from "./components/SignUp/SignUpChat";
import PsycureMessage from "./components/ChatList/PsycureMessage";
import GetInTouch from "./components/GetInTouch/GetInTouch";
import MentalCorporateTable from "./components/AdminDashboard/MentalCorporateTable";
import Policy from "./components/AppTerms/Policy";
import Terms from "./components/AppTerms/Terms";
import Workshop from "./components/Workshop/Workshop";
import WorkshopDetails from "./components/Workshop/TopWorkshop/WorkshopDetails";
import Campaign from "./components/Campaign/Campaign";
import CampaignDetails from "./components/Campaign/CampaignDetails";
import AddWorkShop from "./components/AddWorkshop/AddWorkShop";
import FailedPaymentWorkshop from "./components/Workshop/TopWorkshop/FailedPaymentWorkshop";
import RegisteredWorkshop from "./components/UserProfile/RegisteredWorkshop/RegisteredWorkshop";
import WorkshopUserRegistered from "./components/AdminDashboard/WorkshopUserRegistered";
import WorkshopPromo from "./components/AdminDashboard/WorkshopPromo";
import GiftCard from "./components/GiftCard/GiftCard";
import SingleGiftCard from "./components/GiftCard/SingleGiftCard/SingleGiftCard";
import FailedGiftCard from "./components/GiftCard/FailedGiftCard/FailedGiftCard";
import PaidGiftCard from "./components/UserProfile/PaidGiftCard/PaidGiftCard";
import GiftCardCustomers from "./components/AdminDashboard/GiftCardCustomers";
import React, { useEffect } from "react";
import FellowShipLandingPage from "./components/FellowShip/FellowShipLandingPage";
import ApplyFellowShipModal from "./components/FellowShip/ApplyFellowShipModal";
import FellowshipResponse from "./components/AdminDashboard/FellowshipResponse";
import SuccessfulWorkshopPayment from "./components/Workshop/TopWorkshop/SuccessfulWorkshopPayment";

function App() {
  const { authIsReady } = useAuth();

  ReactGA.initialize("G-H6J87ZMTCE", {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname + window.location.search}`,
      title: "Homepage",
    });
  }, []);

  return (
    authIsReady && (
      <Router>
        <ScrollToTop />
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/fellowship" element={<FellowShipLandingPage />} />
          <Route
            path="/fellowship/submission"
            element={<ApplyFellowShipModal />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/failed-gift-card" element={<FailedGiftCard />} />
          <Route path="/paid-gift-card" element={<PaidGiftCard />} />
          <Route path="/gift-card" element={<GiftCard />} />
          <Route path="/gift-card/:id" element={<SingleGiftCard />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQmain />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/failed-workshop" element={<FailedPaymentWorkshop />} />
          <Route
            path="/paid-workshop"
            element={<SuccessfulWorkshopPayment />}
          />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/workshop/:id" element={<WorkshopDetails />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/campaign/:id" element={<CampaignDetails />} />
          <Route path="/select-doctor" element={<SelectDoctor />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route
            path="/payment"
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path="/paymentSuccess"
            element={
              <PrivateRoute>
                <PaySuccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-next"
            element={
              <PrivateRoute>
                <PaySecondPage />
              </PrivateRoute>
            }
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          {/* no react ga */}
          <Route path="/signup-chat" element={<SignUpChat />} />
          <Route path="/chat-signin" element={<ChatLogin />} />
          {/* no react ga ends */}
          <Route path="/users-journey" element={<UsersJourney />} />
          <Route path="/psychologist-signup" element={<PsychologistSignUp />} />
          <Route path="/psychologist-signin" element={<PsychologistSignIn />} />
          <Route path="/bloger-signup" element={<BlogerSignup />} />
          <Route path="/bloger-signin" element={<BlogerSignin />} />
          {/* no react ga */}
          <Route path="/admin-signin" element={<AdminSignin />} />
          <Route
            path="/psychologist-details"
            element={
              <PsychologistRoute>
                <PsychologistDetailsPage />
              </PsychologistRoute>
            }
          />
          {/* no react ga end */}

          <Route path="/blog" element={<Blogs />} />
          <Route path="/:blogtitle" element={<BlogLearnMore />} />
          <Route path="/allPsychologists" element={<AllPsychologists />} />
          <Route
            path="/psychologists/details/:title"
            element={<PsychologistProfile />}
          />
          <Route
            path="/booksession"
            element={
              <PrivateRoute>
                <BookSession />
              </PrivateRoute>
            }
          ></Route>
          {/* no react ga */}
          <Route
            path="/booksession/:paramCategory"
            element={
              <PrivateRoute>
                <BookSession />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/videoHeroSection"
            element={<VideoSessionHeroSection />}
          />
          <Route
            path="/videoHeroSection/rating"
            element={<RatingVideoSession />}
          />
          <Route path="/session-history" element={<SessionHistory />} />
          {/* no react ga end */}

          <Route path="/SignUpSecondPage" element={<SignUpSecondPage />} />
          {/* no react ga */}
          <Route path="/availableDate" element={<AvailableDate />} />
          <Route path="/wait-for-approval" element={<WaitForApproval />} />

          <Route path="/test" element={<PsychologistDetails />} />
          {/* no react ga */}
          <Route path="/video-session/:id" element={<VideoPage />} />
          <Route path="/corporate-mental-health" element={<GetInTouch />} />
          <Route path="/app/policy" element={<Policy />} />
          <Route path="/app/terms" element={<Terms />} />

          {/* Chat list  no react ga*/}
          <Route
            path="/chat-list"
            element={
              <ChatPrivateRoute>
                {" "}
                <ChatList />
              </ChatPrivateRoute>
            }
          />
          <Route
            path="/chat-list/user"
            element={
              <ChatPrivateRoute>
                {" "}
                <PsycureMessage />
              </ChatPrivateRoute>
            }
          />
          {/* chat list no react ga */}
          <Route path="/service/counseling" element={<Counseling />} />
          <Route
            path="/service/counseling/:title"
            element={<ServiceReadMore />}
          />

          {/* USER DASHBOARD */}

          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <DashboardNavigation />
              </PrivateRoute>
            }
          >
            <Route path="/Dashboard" element={<UserProfile />} />
            {/* <Route path="/Dashboard/homework" element={<HomeWork />} /> */}
            {/* no react ga */}
            <Route
              path="/Dashboard/session-History"
              element={<SessionHistory />}
            />
            <Route path="/Dashboard/edit" element={<EditPatientInfo />} />
            <Route
              path="/Dashboard/registered-workshop"
              element={<RegisteredWorkshop />}
            />

            {/* <Route path="/Dashboard/reports" element={<Reports />} /> */}
            <Route path="/Dashboard/journal" element={<Journal />} />
            <Route path="/Dashboard/recommend" element={<Recommend />} />
            {/* no react ga */}
            <Route
              path="/Dashboard/choose-your-psychologist"
              element={<SelectionOfDoctors />}
            />
            <Route
              path="/Dashboard/choose-your-psychologist/:title"
              element={<PsychologistProfile />}
            />
            <Route
              path="/Dashboard/review/:id"
              element={<RatingVideoSession />}
            />
          </Route>

          {/* PSYCHOLOGIST DASHBOARD */}

          <Route
            path="/psychologist-dashboard"
            element={
              <PsychologistRoute>
                <PsychologistDashboardNavigation />
              </PsychologistRoute>
            }
          >
            <Route
              path="/psychologist-dashboard"
              element={<PsychologistDashboard />}
            />
            <Route
              path="/psychologist-dashboard/homework-builder"
              element={<HomeworkContainer />}
            />
            <Route
              path="/psychologist-dashboard/edit"
              element={<EditPsychologistInfo />}
            />
            <Route
              path="/psychologist-dashboard/calender-view"
              element={<CalenderView />}
            />
            <Route
              path="/psychologist-dashboard/payment-details"
              element={<PsychologistPayment />}
            />
            {/* <Route path="/psychologist-dashboard/reports" element={<Reports />} />
            <Route path="/psychologist-dashboard/journal" element={<Journal />} /> */}
            <Route
              path="/psychologist-dashboard/session/:id"
              element={<SessionDetails />}
            />
          </Route>

          {/* Bloger DASHBOARD */}

          <Route
            path="/bloger-dashboard"
            element={
              <BlogerRoute>
                <BlogerDashboardNavigation />
              </BlogerRoute>
            }
          >
            <Route path="/bloger-dashboard" element={<BlogerDashboard />} />
            <Route path="/bloger-dashboard/addBlog" element={<AddBlog />} />
            <Route path="/bloger-dashboard/info" element={<AddBlogerInfo />} />
          </Route>

          {/* Admin Dashboard */}
          <Route
            path="/admin-dashboard"
            element={
              <AdminRoute>
                <AdminDashboardNavigation />{" "}
              </AdminRoute>
            }
          >
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route
              path="/admin-dashboard/gift-card-customers"
              element={<GiftCardCustomers />}
            />
            <Route path="/admin-dashboard/users" element={<UsersTable />} />
            <Route
              path="/admin-dashboard/payment-review/:id"
              element={<PsychologistPaymentDetails />}
            />
            <Route
              path="/admin-dashboard/psychologist"
              element={<AllPsychologistTable />}
            />
            <Route
              path="/admin-dashboard/fellowship"
              element={<FellowshipResponse />}
            />
            <Route
              path="/admin-dashboard/psychologist/:id"
              element={<AdminPsychologistDetails />}
            />
            <Route path="/admin-dashboard/addBlog" element={<AddBlog />} />
            <Route
              path="/admin-dashboard/addWorkshop"
              element={<AddWorkShop />}
            />
            <Route
              path="/admin-dashboard/registeredUser"
              element={<WorkshopUserRegistered />}
            />
            <Route path="/admin-dashboard/allBlog" element={<AllBlogs />} />
            <Route path="/admin-dashboard/promo-code" element={<PromoCode />} />
            <Route
              path="/admin-dashboard/workshop-promo-code"
              element={<WorkshopPromo />}
            />
            <Route
              path="/admin-dashboard/chat-user"
              element={<AddChatUser />}
            />
            <Route
              path="/admin-dashboard/get-in-touch"
              element={<MentalCorporateTable />}
            />
            {/* <Route path="/admin-dashboard/blog-category" element={<BlogCategory />} /> */}
            <Route
              path="/admin-dashboard/payment-request"
              element={<PsychologistPaymentRequest />}
            />
          </Route>
          <Route path="*" element={<PageNoteFound />} />
        </Routes>
      </Router>
    )
  );
}

export default App;
