import { Button, Grid, MenuItem } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import footerImage from "../../assets/images/footer-bg.png";
import logo from "../../assets/images/Psycure logo.png";
import leaf from "../../assets/images/leaf.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

import { NavHashLink } from "react-router-hash-link";
import { useTheme } from "@material-ui/core/styles";
import "./Footer.css";

const Footer = () => {
  let [email, setEmail] = useState("");
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  const theme = useTheme();

  const handleNewsletter = async (e) => {
    e.preventDefault();
    let details = {
      email,
      time: new Date(),
    };

    if (email === "") {
      alert("email is required");
      return;
    }

    const response = await fetch("https://api.thepsycure.com/newsletter", {
      method: "POST",
      body: JSON.stringify(details),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      setError("please, try again.");
      setSuccess("");
    }
    if (response.ok) {
      setEmail("");
      setError("");
      setSuccess("You will get weekly newsletter from psycure.");
    }
  };

  return (
    <div>
      <img
        style={{ width: "100%", height: "auto" }}
        src={footerImage}
        alt="Footer Background"
      />
      <Box className="second-footer" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Container>
              {/* The Psycure logo */}
              <Box>
                <img
                  className="logo FooterPsycureLogo"
                  src={logo}
                  alt="Psycure logo"
                />
                <img
                  src={leaf}
                  alt="Psycure leaf"
                  className="FooterPsycureLeaf"
                />
              </Box>
              <p
                style={{
                  width: "23rem",
                  paddingRight: "5rem",
                  color: "white",
                  textAlign: "left",
                }}
                className="footerText"
              >
                Psycure organization is one of the fastest-growing mental health
                organizations in Bangladesh and a one-stop digital solution
                platform for mental health and emotional well-being, working
                towards empowering Bangladesh through breaking the stigma and
                providing holistic services for mental health and well-being.
              </p>
            </Container>
          </Grid>
          <Grid container spacing={2} item xs={12} md={8}>
            <Grid
              className="changePadding"
              style={{ paddingLeft: "3rem" }}
              container
              spacing={2}
              item
              xs={12}
              md={8}
            >
              <Grid
                className="footerCenter"
                container
                rowSpacing={2}
                columnSpacing={{ xs: 4, sm: 2, md: 6 }}
                item
                xs={4}
                sx={{ mt: 5 }}
              >
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/home"
                  >
                    Home
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/terms-condition"
                  >
                    Terms & Conditions
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/about-us"
                  >
                    About
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavHashLink
                    to="/#services"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Services
                  </NavHashLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/blog"
                  >
                    Blog
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/teams"
                  >
                    <p style={{ margin: 0 }}>Team</p>
                  </NavLink>
                </Grid>

                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/psychologist-signin"
                  >
                    Psychologist SIGN UP/LOGIN
                  </NavLink>
                </Grid>

                {/* Extra link added */}
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/"
                  >
                    News & Press
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/"
                  >
                    Exhibition
                  </NavLink>
                </Grid>
                <Grid item md={6} xs={12}>
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/"
                  >
                    Gift Card
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className="footer-lastBox">
              <Box className="psychologistSignIn">
                <img
                  src={leaf}
                  alt="Psycure leaf"
                  className="FooterPsycureLeaf"
                />
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to="/contact-us"
                >
                  <Button
                    style={{
                      backgroundColor: "#31C75A",
                      padding: "15px 25px 15px 25px",
                      borderRadius: "15px",
                      margin: "5px 0px 10px 0px",
                    }}
                    variant="contained"
                  >
                    Contact Us
                  </Button>
                </Link>
              </Box>
            </Grid>

            <Grid
              className="socialMedia"
              style={{ paddingLeft: "3rem", marginTop: "1rem" }}
              container
              spacing={2}
              item
              xs={12}
              md={12}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 6 }}
                item
                xs={4}
                sx={{ mt: 5, mb: 5, pb: 3 }}
                style={{
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    display: "flex",
                  }}
                  className="socialMediaSingle"
                >
                  <LocationOnIcon style={{ paddingRight: 5 }} />
                  Location- Flat- 2B,House- 10, Road- 10,Banani- 1213, Dhaka
                </Grid>

                <Grid
                  item
                  md={5}
                  xs={12}
                  style={{ textDecoration: "none", color: "white" }}
                  className="socialMediaSingle details"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <MailOutlineIcon style={{ paddingRight: 5 }} />
                    psycureorganization@gmail.com
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <PhoneIcon style={{ paddingRight: 5 }} /> (+880)
                    01722-318724
                  </div>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    display: "flex",
                  }}
                  className="socialMediaSingle"
                >
                  <Box className="social_container">
                    <a
                      href="https://www.facebook.com/PsycureOrganization"
                      target="_blank"
                      style={{ textDecoration: "none", color: "currentcolor" }}
                    >
                      <FacebookIcon className="social" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/psycure-limited/mycompany/?viewAsMember=true"
                      target="_blank"
                      style={{ textDecoration: "none", color: "currentcolor" }}
                    >
                      <LinkedInIcon className="social" />
                    </a>
                    <a
                      href="https://www.youtube.com/@psycureorganization4980"
                      target="_blank"
                      style={{ textDecoration: "none", color: "currentcolor" }}
                    >
                      <YouTubeIcon className="social youtube" />
                    </a>
                    <a
                      href="https://www.instagram.com/psycureorganization/?hl=en"
                      target="_blank"
                      style={{ textDecoration: "none", color: "currentcolor" }}
                    >
                      <InstagramIcon className="social" />
                    </a>
                  </Box>
                </Grid>
                {/* <Grid item md={4} xs={12} style={{ textDecoration: 'none', color: 'white', display: "flex" }} className='socialMediaSingle'>
                                    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>

                                        <PhoneIcon style={{ paddingRight: 5 }} /> (+880) 01722-318724
                                    </div>
                                </Grid> */}
              </Grid>
            </Grid>

            <Grid
              className="newsletter"
              alignItems="center"
              container
              spacing={2}
            >
              <Grid item md={4} xs={12}>
                <p style={{ fontWeight: "700" }}>Join our newsletter</p>
                <p>
                  Mental health news and tips delivered to your inbox weekly
                </p>
              </Grid>
              <Grid item md={6} xs={12} className="newsletter_form">
                <form className="form" onSubmit={handleNewsletter}>
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="youremail@gmail.com"
                  />
                  <input className="submit" type="submit" value="Submit" />
                </form>
                {success !== "" ? (
                  <p style={{ color: "#31C75A" }}>{success}</p>
                ) : (
                  ""
                )}
                {error !== "" ? <p style={{ color: "red" }}>{error}</p> : ""}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          style={{
            color: "white",
            display: "flex",
            gap: "2rem",
            justifyContent: "center",
            padding: "3rem",
            textAlign: "center",
          }}
        >
          <div>
            <p>Copyright © 2022 All Rights Reserved by Psycure Limited</p>
            {/* <NavLink style={{ textDecoration: 'none', color: 'white' }} to='/terms-condition'>
                            <p>Terms & Conditions</p>
                        </NavLink> */}
          </div>
          <img src={leaf} alt="Psycure leaf" className="FooterPsycureLeaf" />
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
