import React, { useEffect, useState } from "react";
import { Button, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import "./Drawer.css";
import useAuth from "../../hooks/useAuth";

import { useLogout } from "../../hooks/useLogout";
import { NavHashLink } from "react-router-hash-link";

import reviewMan from "../../assets/images/reviewMan.png";
import reviewFemale from "../../assets/images/reviewFemale.png";

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user } = useAuth();
  const { Logout } = useLogout();

  const handleLogout = (e) => {
    Logout();
  };

  const [psycureUsers, setPsycureUsers] = useState([]);

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        setPsycureUsers(data);
      });
  }, [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  console.log("psycureUsers", psycureUsers);
  console.log("user", user);

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box className="navigation">
          <NavLink
            to="/"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Home
            </Button>
          </NavLink>
          <NavLink
            to="/about-us"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              About
            </Button>
          </NavLink>
          <NavHashLink
            to="/#services"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Services
            </Button>
          </NavHashLink>
          <NavLink
            to="/blog"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Blog
            </Button>
          </NavLink>
          <NavLink
            to="/faq"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Faq
            </Button>
          </NavLink>
          <NavLink
            to="/fellowship"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Fellowship
            </Button>
          </NavLink>
          <NavLink
            to="/contact-us"
            style={{
              textDecoration: "none",
              color: "black",
              textAlign: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Contacts
            </Button>
          </NavLink>
          {psycureUsers?.role === "patient" ? (
            <NavLink
              to="/booksession"
              style={{
                textDecoration: "none",
                color: "black",
                textAlign: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#31C75A",
                  padding: "10px",
                  borderRadius: "15px",
                }}
                variant="contained"
              >
                Book a Session
              </Button>
            </NavLink>
          ) : (
            <></>
          )}
        </Box>
        {user?.email ? (
          <Box sx={{ color: "black", textAlign: "center" }}>
            <Box sx={{ my: 2 }}>
              {
                (psycureUsers?.role === "patient" &&
                  psycureUsers?.patientInfo?.gender == "female") ||
                ((psycureUsers?.role === "psychologist" ||
                  psycureUsers?.role == "bloger") &&
                  psycureUsers?.gender == "female") ? (
                  <img
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    src={reviewFemale}
                    alt=""
                    style={{
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "100px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    src={reviewMan}
                    alt=""
                    style={{
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "100px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                  />
                )
                // <AccountCircleIcon
                //     fontSize="large"
                //     onClick={handleClick} style={{ cursor: 'pointer', color: '#31C75A' }} />
              }
            </Box>
            <Box className="sub-menu sub-menu_drawer">
              <p>{user?.displayName}</p>
              {psycureUsers?.role === "psychologist" ? (
                <NavLink
                  to="/psychologist-dashboard"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <Button style={{ fontFamily: "Poppins" }} color="inherit">
                    Dashboard
                  </Button>
                </NavLink>
              ) : psycureUsers?.role === "admin" ? (
                <NavLink
                  to="/admin-dashboard"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <Button style={{ fontFamily: "Poppins" }} color="inherit">
                    Dashboard
                  </Button>
                </NavLink>
              ) : psycureUsers?.role === "bloger" ? (
                <NavLink
                  to="/bloger-dashboard"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <Button style={{ fontFamily: "Poppins" }} color="inherit">
                    Dashboard
                  </Button>
                </NavLink>
              ) : (
                <NavLink
                  to="/Dashboard"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <Button style={{ fontFamily: "Poppins" }} color="inherit">
                    Dashboard
                  </Button>
                </NavLink>
              )}

              <Button
                style={{ fontFamily: "Poppins" }}
                onClick={handleLogout}
                color="inherit"
              >
                Logout
              </Button>
            </Box>
          </Box>
        ) : (
          <NavLink
            to="/signin"
            style={{
              textDecoration: "none",
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button style={{ fontFamily: "Poppins" }} color="inherit">
              Sign In
            </Button>
          </NavLink>
        )}
      </Drawer>
      <IconButton
        sx={{ color: "black" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="black" sx={{ overflowX: "hidden" }} />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
